<template>
  <div class="live-mini-program gray-bcg-color">
    <c-banner :bannerData="bannerData"></c-banner>
    <div class="advantage  white_bcg-color">
      <div class="c-width">
        <c-title :titleArr="advantageTitle"></c-title>
        <ul class="advantage-list marginTop">
          <li
            class="advantage-item"
            v-for="(item, index) in advantageData"
            :key="index"
          >
            <img class="bcg" :src="item.bcg" alt="" width="282" />
            <img :src="item.src" alt="" width="184" />
            <p class="title">{{ item.title }}</p>
            <div class="line"></div>
            <div class="text-wrap">
              <div class="text" v-for="text in item.textData" :key="text">
                {{ text }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="function">
      <c-title :titleArr="functionTitle"></c-title>
      <div class="function-content c-width marginTop">
        <img
          class="function-img"
          src="../../../assets/images/program/function@2x.png"
          width="571"
          alt=""
        />
        <c-product
          :productData="functionData"
          :isProgram="true"
          target=""
        ></c-product>
      </div>
    </div>
    <div class="solution c-width">
      <c-title :titleArr="solutionTitle"></c-title>
      <div class="img-wrap marginTop">
        <el-image
          :src="require('../../../assets/images/program/solution@2x.png')"
          lazy
          alt=""
        ></el-image>
      </div>
    </div>
    <div class="product">
      <c-title :titleArr="productTitle"></c-title>
      <c-product :productData="productData"></c-product>
    </div>
    <div class="scene c-width">
      <c-title class="title" :titleArr="sceneTitle"></c-title>
      <c-scene :sceneData="sceneData"></c-scene>
    </div>
    <div class="doc marginTop">
      <div class="doc-content c-width">
        <c-title :titleArr="documentTitle" :isDocTitle="true"></c-title>
        <div class="content">
          <a :href="`${docSrc}docs/show/2.html`" target="_blank"
            >直播服务开发指南</a
          >
          <a :href="`${docSrc}docs/show/181.html`" target="_blank"
            >点播服务开发指南</a
          >
          <a :href="`${docSrc}docs/show/144.html`" target="_blank"
            >小程序开发指南</a
          >
          <a :href="`${docSrc}docs/show/145.html`" target="_blank"
            >小程序使用指南</a
          >
        </div>
      </div>
    </div>
    <c-customized :href="bannerData.href2"></c-customized>
  </div>
</template>

<script>
import { cBanner, cProduct, cScene, cCustomized } from '../components'
import { cTitle } from '@/components'
export default {
  components: {
    cBanner,
    cProduct,
    cScene,
    cCustomized,
    cTitle
  },
  data () {
    return {
      docSrc: window.urlConfig.docUrl,
      documentTitle: ['接入文档'],
      sceneData: [
        {
          src: require('../../../assets/images/program/scene1@2x.png'),
          text: '在线教育'
        },
        {
          src: require('../../../assets/images/program/scene2@2x.png'),
          text: '银行证券'
        },
        {
          src: require('../../../assets/images/program/scene3@2x.png'),
          text: '企业办公'
        },
        {
          src: require('../../../assets/images/program/scene4@2x.png'),
          text: '在线医疗'
        }
      ],
      sceneTitle: ['应用场景'],
      productData: [
        {
          className: 'live',
          title: '直播服务',
          text: '全流程直播服务，快速部署搭建直播业务',
          href: `//${window.location.host}/live`
        },
        {
          className: 'vod',
          title: '点播服务',
          text: '一站式点播服务，快速搭建点播平台',
          href: `//${window.location.host}/vod`
        }
      ],
      productTitle: ['方案产品'],
      solutionTitle: ['方案架构'],
      functionData: [
        {
          href: 'javascript:void(0)',
          src: require('../../../assets/images/program/function1@2x.png'),
          title: '小程序源码',
          text:
            '不需要您具备专业的音视频基础知识，使用微吼云提供的源码， 即可让您轻松拥有属于自己的直播小程序。'
        },
        {
          href: 'javascript:void(0)',
          src: require('../../../assets/images/program/function2@2x.png'),
          title: '优质稳定的服务',
          text:
            '全链路优化、全球CDN布点，为您提供低延时、高并发的直播服务，优质稳定的服务为您的直播保驾护航。'
        },
        {
          href: 'javascript:void(0)',
          src: require('../../../assets/images/program/function3@2x.png'),
          title: '一站式解决方案',
          text:
            '提供从直播接入到分发观看的一站式直播服务，涵盖转码、录制、AI鉴黄等直播功能，用于满足您多场景使用。'
        },
        {
          href: 'javascript:void(0)',
          src: require('../../../assets/images/program/function4@2x.png'),
          title: '多终端支持',
          text:
            '除了微信小程序，解决方案还包括 iOS、Android、PC在内的多终端SDK，使您的产品线可以覆盖到多个平台。'
        }
      ],
      advantageTitle: ['方案优势'],
      functionTitle: ['方案功能'],
      advantageData: [
        {
          bcg: require('../../../assets/images/program/advantage1_2@2x.png'),
          src: require('../../../assets/images/program/advantage1_1@2x.png'),
          title: '零基础快速对接',
          textData: [
            '一站式音视频解决方案',
            '通过集成微吼云提供的源码，即可快速进行开发',
            '实现直播能力'
          ]
        },
        {
          bcg: require('../../../assets/images/program/advantage2_2@2x.png'),
          src: require('../../../assets/images/program/advantage2_1@2x.png'),
          title: '超低延时',
          textData: ['端到端延时小于400ms', '抗30%网络丢包，自适应网络变化']
        },
        {
          bcg: require('../../../assets/images/program/advantage3_2@2x.png'),
          src: require('../../../assets/images/program/advantage3_1@2x.png'),
          title: '高完成度Demo',
          textData: [
            '高完成度Demo',
            '扫码即可体验直播小程序的全流程服务及全套功能'
          ]
        }
      ],
      bannerData: {
        ways: 'live-mini-program',
        title: '直播小程序解决方案',
        text: [
          '一站式直播解决方案，帮助客户一天完成直播小程序的集成搭建',
          '助力企业探索创新新服务模式'
        ],
        href2: `//${window.location.host}/custom?refer=program`,
        href1: 'https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038',
        text1: '咨询我们',
        text2: '申请定制'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.marginTop {
  margin-top: 40px;
}
.advantage-list {
  display: flex;
  .advantage-item {
    position: relative;
    height: 524px;
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      159.7deg,
      rgba(27, 206, 223, 1),
      rgba(91, 36, 122, 1)
    );
    border-radius: 20px 0 0 20px;
    transition: width 1s ease-in-out;
    overflow: hidden;
    &:nth-child(1) {
      width: 610px;
      .line {
        display: block;
      }
      .text-wrap {
        display: block;
      }
    }
    &:nth-child(2) {
      border-radius: 0;
      background: linear-gradient(
        157.1deg,
        rgba(23, 234, 217, 1),
        rgba(96, 120, 234, 1)
      );
    }
    &:nth-child(3) {
      border-radius: 0 20px 20px 0;
      background: linear-gradient(
        164.5deg,
        rgba(66, 230, 149, 1),
        rgba(59, 178, 184, 1)
      );
    }
    .bcg {
      position: absolute;
      bottom: 0;
    }
    .title {
      margin-top: 28px;
      font-size: 30px;
      font-weight: 500;
      color: #fff;
    }
    .line {
      width: 60px;
      height: 6px;
      margin-top: 24px;
      background: #fff;
      border-radius: 6px;
      display: none;
    }
    .text-wrap {
      margin-top: 26px;
      font-size: 18px;
      color: #fff;
      text-align: center;
      display: none;
    }
  }
  &:hover {
    .advantage-item {
      width: 320px;
      .text-wrap,
      .line {
        display: none;
      }
      &:hover {
        width: 610px;
        .text-wrap,
        .line {
          display: block;
        }
      }
    }
  }
}

.function-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  .function-img {
    // /deep/ img {
    //   width: 571px;
    // }
  }
}
.solution {
  img {
    width: 100%;
  }
}
.doc {
  width: 100%;
  height: 236px;
  padding-top: 44px;
  background: url('../../../assets/images/program/doc_bg@2x.png') no-repeat;
  background-size: cover;
  .doc-content {
    padding-top: 4px;
    .content {
      display: grid;
      grid-template-columns: repeat(4, 25%);
      margin-top: 53px;
      a {
        font-size: 18px;
        color: #e1e1e1;
        font-weight: 500;
        text-align: center;
        &:not(:first-child) {
          border-left: 1px solid #fff;
        }
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
